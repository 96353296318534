import React from 'react';
import { Icon } from 'components';

interface RouterType {
  name: string;
  roleKey: string;
  isTopMenu?: boolean;
  showLeftMenu?: boolean;
  path?: string;
  badgeText?: string;
  icon?: any;
  children?: RouterType[];
  disabled: boolean; // 是否禁用 -- 禁用后跳到该页面会重定向
  showMenu?: boolean; // 是否显示 -- 可通过history跳转
}

const routers: RouterType[] = [
  {
    roleKey: "index",
    name: "首页",
    badgeText: "",
    isTopMenu: true,
    showLeftMenu: false,
    path: "/index",
    disabled: false
  },
  {
    roleKey: "purchase",
    name: "采购管理",
    badgeText: "",
    isTopMenu: true,
    showLeftMenu: true,
    path: "/purchase",
    disabled: false,
    children: []
  },
  {
    roleKey: "psm",
    name: "PSM数字协同",
    badgeText: "",
    isTopMenu: true,
    showLeftMenu: false,
    path: "https://www.baidu.com/3",
    disabled: false
  },
  {
    roleKey: "programme",
    name: "方案云",
    badgeText: "",
    isTopMenu: true,
    showLeftMenu: false,
    path: "https://www.baidu.com/1",
    disabled: false
  },
  {
    roleKey: "marketing",
    name: "营销云",
    badgeText: "",
    isTopMenu: true,
    showLeftMenu: false,
    path: "https://www.baidu.com/2",
    disabled: false
  },
  {
    roleKey: "card",
    name: "数字卡券",
    badgeText: "",
    isTopMenu: true,
    showLeftMenu: false,
    path: "https://www.baidu.com/4",
    disabled: false,
  },
  {
    roleKey: "professional_solutions",
    name: "专业解决方案",
    badgeText: "",
    isTopMenu: true,
    showLeftMenu: false,
    path: "https://www.baidu.com/5",
    disabled: false,
  },
  {
    roleKey: "member:finance:manage",
    name: "账户管理",
    badgeText: "",
    isTopMenu: true,
    showLeftMenu: true,
    path: "/capital",
    disabled: false,
    children: [
      {
        roleKey: "member:finance:manage:accountManage",
        name: "账户管理",
        badgeText: "",
        path: "/capital/account",
        disabled: false,
        showMenu: true,
        icon: <Icon type="iconzichanguanli" className="ant-menu-item-icon" />
      },
      {
        roleKey: "member:finance:manage:accountRecharge",
        name: "预付货款",
        badgeText: "",
        path: "/capital/recharge",
        disabled: false,
        showMenu: false,
      },
      {
        roleKey: "member:finance:manage:accountTransaction",
        name: "交易明细",
        badgeText: "",
        path: "/capital/transaction",
        disabled: false,
        showMenu: false,
      },
      {
        roleKey: "member:finance:manage:settlement",
        name: "交易与结算中心",
        badgeText: "",
        path: "/capital/settlement",
        disabled: false,
        showMenu: true,
        icon: <Icon type="iconziyuanguanli" className="ant-menu-item-icon" />
      },
      {
        roleKey: "member:finance:manage:invoice",
        name: "发票管理",
        badgeText: "",
        path: "/capital/invoice",
        disabled: false,
        showMenu: true,
        icon: <Icon type="icondingdanguanli" className="ant-menu-item-icon" />
      },
      {
        roleKey: "member:finance:manage:reconciliation",
        name: "对账中心",
        badgeText: "",
        path: "/capital/reconciliation",
        disabled: false,
        showMenu: true,
        icon: <Icon type="iconcaigouguanlihoutai_huabanfuben3" className="ant-menu-item-icon" />
      },
    ]
  },
  {
    roleKey: "data",
    name: "数据中心",
    badgeText: "",
    isTopMenu: true,
    showLeftMenu: true,
    path: "/index33",
    disabled: false,
    children: []
  },
  {
    roleKey: "account",
    name: "账号中心",
    badgeText: "",
    isTopMenu: true,
    showLeftMenu: true,
    path: "/index22",
    disabled: false,
    children: [

    ]
  },
  {
    roleKey: "setting",
    name: "配置中心",
    badgeText: "",
    isTopMenu: true,
    showLeftMenu: true,
    path: "/index11",
    disabled: false,
    children: []
  },
];


export  {routers};
