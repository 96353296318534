/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-03-22 16:53:05
 * @LastEditors: asheng
 * @LastEditTime: 2021-04-01 13:57:02
 */
import { makeAutoObservable, configure } from "mobx";
import { account } from "api";
import { httpClient } from "tools";

configure({ enforceActions: "observed" });

class AccountStore {
  [key: string]: any;
  constructor() {
    makeAutoObservable(this);
  }

  // 有没有设置密码
  checkHasSetPassword = async (params: any = {}) => {
    return (await httpClient.post(
      account.get("checkHasSetPassword") as string,
      { ...params }
    )) as any;
  };

  // 获取管理员手机号
  getAdminTel = async (params: any = {}) => {
    return (await httpClient.post(account.get("getAdminTel") as string, {
      ...params,
    })) as any;
  };

  // 设置密码
  setPassword = async (params: any = {}) => {
    return (await httpClient.post(account.get("setPassword") as string, {
      ...params,
    })) as any;
  };

  // 给管理员发短信验证码
  sendSmsToAdmin = async (params: any = {}) => {
    return (await httpClient.post(account.get("sendSmsToAdmin") as string, {
      ...params,
    })) as any;
  };

  // 【收银台】获取支付方式接口
  getPaymentList = async (params: any = {}) => {
    return (await httpClient.post(account.get("getPaymentList") as string, {
      ...params,
    })) as any;
  };
  // mall -PAY-lIST
  mallPayList = async (params: any = {}) => {
    return (await httpClient.post(account.get("mallPayList") as string, {
      ...params,
    })) as any;
  };

  // mall -PAY-lIST
  getPayMobile = async (params: any = {}) => {
    return (await httpClient.post(account.get("getPayMobile") as string, {
      ...params,
    })) as any;
  };

  payResult = async (params: any = {}) => {
    return (await httpClient.post(account.get("payResult") as string, {
      ...params,
    })) as any;
  };
  sendSmres = async (params: any = {}) => {
    return (await httpClient.post(account.get("sendSmss") as string, {
      ...params,
    })) as any;
  };
  smsCount = async (params: any = {}) => {
    return (await httpClient.post(account.get("smsCount") as string, {
      ...params,
    })) as any;
  };
  goPay = async (params: any = {}) => {
    return (await httpClient.post(account.get("goPay") as string, {
      ...params,
    })) as any;
  };

  // 【收银台】去支付接口
  pay = async (params: any = {}) => {
    return (await httpClient.post(account.get("pay") as string, {
      ...params,
    })) as any;
  };

  // 获取默认结算账户 收银台对公账户
  getAccount = async (params: any = {}) => {
    return (await httpClient.get(account.get("getAccount") as string, {
      params,
    })) as any;
  };

  // 收银台对公账户 PDF
  downloadPdf = async (params: any = {}) => {
    return (await httpClient.post(account.get("downloadPdf") as string, {
      ...params,
    })) as any;
  };

  // 收银台对公账户 发送短信
  sendSms = async (params: any = {}) => {
    return (await httpClient.post(account.get("sendSms") as string, {
      ...params,
    })) as any;
  };

  // 收银台 获取单个订单简单信息
  getOrderInfoByPayBillNo = async (params: any = {}) => {
    return (await httpClient.get(
      account.get("getOrderInfoByPayBillNo") as string,
      { params }
    )) as any;
  };
  mallPay= async (params: any = {}) => {
    return (await httpClient.post(account.get("mallPay") as string, {
      ...params,
    })) as any;
  };
	// 【收银台】获取对公账户信息
	publicAccount = async (params: any = {}) => {
		return (await httpClient.post(account.get('publicAccount') as string, {
			...params
		})) as any;
	};

	// 【收银台】提交支付凭证
	publicVoucher = async (params: any = {}) => {
		return (await httpClient.post(account.get('publicVoucher') as string, {
			...params
		})) as any;
	};
}

export default new AccountStore();
