/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-04-01 11:29:13
 * @LastEditors: asheng
 * @LastEditTime: 2021-04-01 11:45:39
 */
import React from "react";
import { createFromIconfontCN } from "@ant-design/icons";
import "./index.less";
// antd 字体图标
const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_1557419_kughl0mwhpa.js"
});

interface MainProps {
  [key: string]: any;
  type: string;
}
const Icon: React.FC<MainProps> = (props) => <IconFont {...props} />;
export default Icon;