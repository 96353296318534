import React from 'react';
import './index.less';
interface MainProps {
    [key: string]: any;
}

/**
 * 
 * Footer
 */
const Footer: React.FC<MainProps> = (props): JSX.Element => {
    const goBeian = () => {
        window.location.href = "https://beian.miit.gov.cn/";
    }
    const goGongAn = () => {
        window.open(
            "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602008661"
        );
    }
    return (
        <div className='Footer'>
            <div className='Footer_area'>
                <div className='Footer_area_left'>
                    <img src="https://oss.elebuys.com/tmpdir/202106092118010003959999.png" alt="" />
                </div>
                <div className='Footer_area_right'>
                    <div className='Footer_area_right_area'>
                        <div className='Footer_area_right_head'>
                            <div className="hands" onClick={() => { window.open(`https://www.fxqifu.com/`);}}>飞象企服</div>
                            <div>象买商城</div>
                            <div className="hands" onClick={() => { window.open(`https://www.fxliye.com/`);}}>百礼腾</div>
                        </div>
                        <div className='Footer_area_right_mid'>
                            <div className='Footer_area_right_mid_area'>
                                <div>增值电信业务经营许可证：浙B2-20170751 </div>
                                <div className="border_lr hands" onClick={goBeian}>浙ICP备17028214号-9 </div>
                                {/* <div>可信网站备案：xxxxxxx</div> */}
                                <div className="hands" onClick={goGongAn}>
                                    <img src="https://oss.elebuys.com/tmpdir/202106291141540003965077.png" /> 浙公网安备33010602008661
                                </div>
                            </div>
                            川网文 「2020」4322-906号
                        </div>
                        <div className='Footer_area_right_foot'>
                            ©2014-2021 fxqifu.com. All rights reserved杭州飞象企服网络技术有限公司
                    </div>
                    </div>
                </div>
            </div>
        </div >
    );
};
export default Footer;