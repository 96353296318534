import React from 'react';
import { observer } from 'mobx-react';
import './index.less';
import { CommonStore } from 'stores';
interface MainProps {
	[key: string]: any;
}

/**
 *
 * Header
 */
const Header: React.FC<MainProps> = (props): JSX.Element => {
	return (
		<div className="header">
			<div className="title" style={{ width: '100%', textAlign: 'center' }}>
				收银台
			</div>
		</div>
	);
};

export default observer(Header);
