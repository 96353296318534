/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-03-22 16:53:05
 * @LastEditors: asheng
 * @LastEditTime: 2021-04-01 13:57:02
 */
import { makeAutoObservable, configure, runInAction } from 'mobx';
import { CommonTypes } from 'types/store/common.interface';
import { httpClient } from 'tools';
import { basic } from 'api';

configure({ enforceActions: 'observed' });

class CommonStore implements CommonTypes {
	[key: string]: any;
	constructor() {
		makeAutoObservable(this);
	}

	getMemberInfo = async () => {
		return (await httpClient.get(basic.get('getMemberInfo') as string, {})) as any;
	};

	baseInfo: any = {};
	setBaseInfo = (baseInfo: any) => {
		runInAction(() => {
			this.baseInfo = baseInfo;
		});
	};

	redirectUrl: any = '';
	setRedirectUrl = (redirectUrl: any) => {
		runInAction(() => {
			this.redirectUrl = redirectUrl;
		});
	};

	autoRedirect: any = '0';
	setAutoRedirect = (autoRedirect: any) => {
		runInAction(() => {
			this.autoRedirect = autoRedirect;
		});
	};

	type: any = '0'; // 1集采批发 2集采代发 3发货单 4短信包 5卡券电商卡券订单
	setType = (type: any) => {
		runInAction(() => {
			this.type = type;
		});
	};

	platform: any = '0'; // 1集采批发 2集采代发 3发货单 4短信包 5卡券电商卡券订单
	setPlatform = (platform: any) => {
		runInAction(() => {
			this.platform = platform;
		});
	};

	redirectTokenFlag: any = '1'; // redirectTokenFlag 回跳是否拼上token
	setRedirectTokenFlag = (redirectTokenFlag: any) => {
		runInAction(() => {
			this.redirectTokenFlag = redirectTokenFlag;
		});
	};

	showErrModal: boolean = false;
	setShowErrModal = (b: boolean) => {
		runInAction(() => {
			this.showErrModal = b;
			if (b) {
				setTimeout(() => {
					if (navigator.userAgent.indexOf('Firefox') != -1 || navigator.userAgent.indexOf('Chrome') != -1) {
						window.location.href = 'about:blank';
						window.close();
					} else {
						window.opener = null;
						window.open('', '_self');
						window.close();
					}
				}, 3000);
			}
		});
	};

	loginPageInfo = async (param: any) => {
		return (await httpClient.post(basic.get('loginPageInfo') as string, param)) as any;
	};
}

export default new CommonStore();
