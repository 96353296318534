/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-03-22 16:53:05
 * @LastEditors: asheng
 * @LastEditTime: 2021-04-01 13:46:13
 */
const account: Map<string, string> = new Map([
  ["checkHasSetPassword", "/elebuys/pay/pay/password/have"], // 有没有设置密码
  ["getAdminTel", "/elebuys/pay/transfer/getAdminTelephone"], // 获取管理员手机号
  ["setPassword", "/elebuys/member/company/pay-password"], // 设置密码
  ["sendSmsToAdmin", "/elebuys/member/company/sms-code"], // 给管理员发短信验证码

  ["getPicture", "/basic/captcha/get"], // 【收银台】获取支付方式接口
  ["mallPayList", "/bestgift/mall/pay/list"], // 【收银台】获取支付方式接口
  ["getPayMobile", "/bestgift/mall/pay/pay-mobile/get"], // 获取支付手机号
  ["payResult", "/bestgift/mall/pay/pay-result"], // 支付单结果查询
  ["sendSmss", "/bestgift/mall/pay/pay-sms-code/send"], // 发送支付验证码
  ["smsCount", "/bestgift/mall/pay/pay-sms-count"], // 获取支付短信验证码发送限制次数
  ["goPay", "/bestgift/mall/pay/to-pay"], // 去支付v4.0

  ["getPaymentList", "/elebuys/pay/payment/list"], // 【收银台】获取支付方式接口
  ["pay", "/elebuys/pay/payment/toPay"], // 【收银台】去支付接口
  ["getAccount", "/elebuys/pay/payment/account"], // 获取默认结算账户 收银台对公账户
  ["downloadPdf", "/elebuys/pay/payment/download"], // 收银台对公账户 PDF

  ["sendSms", "/elebuys/pay/payment/send/sms"], // 收银台对公账户 发送短信接口

  ["getOrderInfoByPayBillNo", "/elebuys/pay/payment/getOrderInfoByPayBillNo"], // 单个订单获取顶部商品信息

  ["mallPay", "/bestgift/mall/pay/pay-result"], // 单个订单获取顶部商品信息
	['publicAccount', '/bestgift/mall/pay/public-account'], // 【收银台】获取对公账户信息
	['publicVoucher', '/bestgift/mall/pay/pay-voucher/submit'] // 【收银台】提交支付凭证
]);

export default account;
