import React from "react";
import { Spin } from "antd";

interface LoadingProps {}

const Loading: React.FC<LoadingProps> = (props) => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default Loading;
