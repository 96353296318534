/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-03-11 13:40:28
 * @LastEditors: asheng
 * @LastEditTime: 2021-04-01 13:37:55
 */

import React, { useEffect } from "react";
import Loadable from "react-loadable";
import { Loading } from "components";
import { Route, Switch, Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { Header, Footer } from "components";
import PayLayout from "../../views/Pay/index";
import Success from "../../views/Success/index";
import { CommonStore } from "stores";
import "./index.less";

const Basic: React.FC = () => {
  function getUrlParams(url: any) {
    const paramsArray = url.split("?")[1].split("&");
    const params = paramsArray.reduce((acc: any, param: any) => {
      const [key, value] = param.split("=");
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});
    return params;
  }

  const setIcon = async () => {
    const params = getUrlParams(window.location.href);
    const location = params.redirectUrl?.split("/module")[0]?.split("//")[1];
    const link: any = document.getElementById("links");
    const res = await CommonStore.loginPageInfo({
      loginLink: `${location}/account/login`,
    });
    console.log("res --- ", res);
    if (res.success) {
      link.href = res.data?.backendLogo;
      console.log("logo --- ", res.data?.backendLogo);
    }
  };

  useEffect(() => {
    setIcon();
  }, []);

  return (
    <div>
      <Header></Header>
      <Switch>
        <Route path="/pay" exact component={PayLayout} />
        <Route path="/success" exact component={Success} />

        <Redirect from="/" to="/pay" />
      </Switch>
      {/* <Footer></Footer> */}
    </div>
  );
};

export default observer(Basic);
